.formTitleContainer {
  width: 100%;
  margin: 38px 0;
}

.formTitle {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
}

.formItemContainer {
  width: 100%;
  margin-bottom: 38px;
}

.occupancyButtonsContainer {
  width: 100%;
  line-height: 40px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.plusMinusButton {
  font-size: 14px;
  width: 30%;
  min-width: 30px;
  max-width: 60px;
}

.numberOfItemLabel {
  margin: 0;
  font-size: 16px;
}

.listArrangementContainer {
  width: 100%;
}

.listArrangement {
  width: 100%;
}

.listArrangementDetailsContainer {
  width: auto;
  padding: 20px;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.listArrangementPlusMinusButton {
  font-size: 12px;
  width: 30%;
  min-width: 30px;
  max-width: 60px;
}

.bedRoomListError {
  color: #f5222d;
}

@media (min-width: 768px) {
  .listArrangementDetailsContainer {
    margin: 0 0 0 30px;
  }
}
