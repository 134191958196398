.modalTitle {
  margin: -16px -24px -16px -24px; /* the background won't filled the whole panel without this margin */
  padding: 16px;

  display: flex;
  font-weight: bold;
  font-size: 20px;
  background: #fff1f0;
}

.property {
  margin-bottom: 10px;
}

.propertyTitle {
  font-weight: bold;
}

.monthWithYear {
  margin-bottom: 10px;
}

.linkIcon {
  font-size: 12px;
}

.monthWithYearTotalClashingReservationsTitle {
  font-weight: bold;
}

.monthWithYearTotalNumberOfClashes {
  font-size: 14px;
  font-weight: bold;
}
