.headerContainer {
  height: 52px;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 8px;
}
.headerLogo {
  max-height: 40px;
}
.headerTitle {
  font-size: 20px;
  line-height: 52px;
  margin-bottom: 0;
  color: #9e9e9e;
}

.contentContainer {
  width: 100%;
  padding: 0 15%;
  justify-content: center;
}

.footerContainer {
  width: 100%;
  margin-bottom: 38px;
}
.footerNavButton {
  font-size: 14px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
}
