.colorPicker {
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 50%;
}

.notes {
  background-color: #cbf2f436;
  border-color: #cbf2f4;
}

.notes i {
  color: #009da8 !important;
}

.notesText {
  margin-bottom: 0;
  color: #757575;
}

.container {
  min-width: 352px;
  margin-bottom: 16px;
  overflow-x: auto;
  margin: 40px;
}

.connectedaccountRow {
  margin-top: 16px;
}
