.navbarMenu {
  display: inline !important;
}
.intergrationMenuBar {
  display: inline !important;
  padding-left: 5px;
}
.integrateIcon {
  padding-left: 5px;
}
