.youShallNotPass {
  background-color: white !important;
  height: 100%;
  width: 100%;
  background-image: none;
}

.errorRowForException {
  margin: auto;
  padding: 40px;
  width: 100%;
}

.errorColumnForException {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  height: 100%;
}

.image {
  width: 100%;
  max-height: 400px;
}

.errorColumnContent {
  margin: auto;
  height: 400px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.errorTitle {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}

.errorSubtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .image {
    height: 200px;
  }
  .errorColumnContent {
    height: 300px;
  }
  .errorTitle {
    font-size: 56px !important;
    line-height: 56px;
    text-align: center;
  }
  .errorSubtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
