.listArrangementContainer {
  width: 100%;
}

.listArrangementDetailsContainer {
  width: auto;
  padding: 20px;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.bedRoomListError {
  color: #f5222d;
}

@media (min-width: 768px) {
  .listArrangementDetailsContainer {
    margin: 0 0 0 30px;
  }
}
