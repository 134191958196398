.inputNumberLabel {
  margin-bottom: 8px;
}

.default {
  width: 100%;
}

.large {
  font-size: 14px;
}
