.formItem {
  margin-bottom: 38px;
  float: left;
}

.amenitiesTitle {
  word-wrap: break-word !important;
  color: #424242;
}

.amenitiesIcon {
  color: #009da8;
}

.amenitiesSelection {
  width: 100%;
  font-size: 14px;
}
