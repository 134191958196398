.notes {
  background-color: #cbf2f436;
  border-color: #cbf2f4;
}

.notes i {
  color: #009da8 !important;
}

.notesText {
  margin-bottom: 0;
  color: #757575;
}

.defaultFinePrintText {
  background-color: #ffffff;
  border: 1px solid #cbf2f4;
  margin: 8px 0;
  padding: 8px;
  margin-bottom: 0;
  color: #757575;
  font-style: italic;
}
