.card {
  margin-bottom: 25px;
}

.card:last-of-type {
  margin-bottom: 0;
}

.rateModifierFormItemsTitle {
  line-height: 39.9999px;
}
