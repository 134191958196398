.host-form-card {
  margin: 40px;
}

.host-expenses-item {
  margin-bottom: 0px;
}

.host-button-margin {
  margin-left: 8px;
}

.host-form-list {
  padding: 0px 18px;
  width: 50%;
}

.host-form-list-upload-logo {
  display: flex;
  padding: 0px 18px;
  width: 52%;
}

.wrap-text {
  white-space: normal;
}

.host-button-wrapper {
  margin: 40px;
  margin-top: -20px;
  display: block;
  width: 100%;
  padding: 18px 0px;
}

@media screen and (max-width: 650px) {
  .host-form-card {
    margin: 10px;
  }
  .host-form-list {
    width: 100%;
  }
}
