.cardWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}

.card {
  border-top: 3px solid;
  width: 90%;
}

.cardHostProtected {
  border-top-color: #d5ecdb;
}

.cardHostUnprotected {
  border-top-color: #ffc8c8;
}

.title {
  cursor: pointer;
}

.titleProperty {
  color: #bdbdbd;
  font-size: 12px;
  white-space: normal;
  word-wrap: break-word;
}

.titleTotal {
  text-align: right;
  font-size: 12px;
}

.titleUnit {
  font-size: 12px;
  line-height: 20px;
  white-space: normal;
  word-wrap: break-word;
}

.titleTotalAmount {
  font-size: 16px;
  text-align: right;
  color: #bdbdbd;
}

.titleTotalAmountNumber {
  color: #00b9c6;
  white-space: normal;
  word-wrap: break-word;
}

.actionLink {
  color: #00b9c6;
  font-size: 14px;
}

.generalDetailsText {
  font-size: 16px;
  font-weight: 450;
  cursor: pointer;
  margin-bottom: 15px;
}

.generalDetailsTextEarning {
  color: #00b9c6;
}

.generalDetailsTextNoEarning {
  font-size: 15px;
  justify-content: center;
  color: #ea9302;
}

.generalDetailsButton {
  display: flex;
  justify-content: center;
}
