.csvContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.al-list-card {
  margin: 30px;
  min-width: 260px;
}
