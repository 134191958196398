.cardTitleButton {
  float: right;
}

.listRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0% -2% 0% -2%;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 1%;
  min-height: 100px;
  word-break: break-all;
}

.userProfileName {
  font-size: 110%;
  color: black;
}

.userDetails {
  font-size: 90%;
  color: #a8a8a8;
}

.propertyName {
  color: #424242;
}

/* Andre - do not remove autoprefixer, it is not a comment, it is a css */
.unitName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}

.checkInOut {
  font-weight: 500;
  color: #9e9e9e;
  text-align: center;
}

.checkInOutToday {
  background: rgb(198, 252, 212);
  border-radius: 8px;
  border: 1px solid rgb(90, 230, 127);
  color: #289d48;
}

.checkInOutTomorrow {
  background: rgb(232, 245, 235);
  border-radius: 8px;
  border: 1px solid rgb(209, 209, 209);
  color: #757575;
}

.footer {
  font-size: 150%;
  font-weight: 400;
}

.footerDataSize {
  text-align: center;
  font-weight: 600;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
