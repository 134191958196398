.header {
  margin: 30px 0 0 30px;
}

.sp-controls {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sp-csv {
  float: right;
}

.links {
  color: #00b9c6;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}

.links:hover {
  color: #22ced4;
}

.modal-receipts-col {
  margin-top: 10px;
}

.modal-receipts-btn {
  width: 100%;
}
