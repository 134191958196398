.overviewBody {
  padding: 30px;
  min-width: 700px;
}

.warningOnClashingReservationLink:active {
  text-decoration: none; /* hide the underline after click on the Alert */
}

.warningOnClashingReservationRow {
  margin-bottom: 8px;
}

.warningOnClashingReservationRow:hover {
  cursor: pointer;
}

.warningOnClashingReservationIcon {
  color: red;
  margin-right: 10px;
}

.warningOnClashingReservationTotalNumber {
  font-weight: bold;
}

.warningOnOverdueLink:active {
  text-decoration: none; /* hide the underline after click on the Alert */
}

.warningOnOverdueRow {
  margin-bottom: 8px;
}

.warningOnOverdueRow:hover {
  cursor: pointer;
}

.warningOnOverdueIcon {
  color: red;
  margin-right: 10px;
}

.warningOnInvoiceLink:active {
  text-decoration: none; /* hide the underline after click on the Alert */
}

.warningOnInvoiceRow {
  margin-bottom: 20px;
}

.warningOnInvoiceRow:hover {
  cursor: pointer;
}

.warningOnInvoiceIcon {
  color: orange;
  margin-right: 10px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 20px;
  z-index: 0;
}

.dateRangePicker {
  margin-left: 5px;
}

.dashboardsRow {
  align-content: top;
  justify-content: center;
}

.dashboard {
  margin-top: 10px;
  min-height: 545px;
  padding: 10px 10px 10px 10px;
}

.summaryTitle {
  font-weight: 700;
  font-size: 25px;
}

.summaryBody {
  font-weight: 300;
  font-size: 35px;
}

@media (max-width: 789px) {
  .buttonRow {
    flex-direction: column;
  }

  .dateRangePicker {
    margin-left: 0px;
    margin-top: 5px;
  }
}
