.checklist-form-card {
  margin: 40px;
}
.checklist-form-wrapper {
  display: flex;
}
.checklist-form-list {
  padding: 0px 18px;
  display: block;
  width: 100%;
}
.checklist-form-list-half {
  padding: 0px 18px;
  display: block;
  width: 50%;
}
.checklist-table-wrapper {
  display: block;
  width: 100%;
}
.checklist-table-column {
  min-width: 6em;
}
.checklist-button-wrapper {
  display: block;
  width: 100%;
}

.checklist-info-wrapper {
  width: 100%;
  margin-bottom: 15px;
}
.save-and-delete-buttons {
  margin: 40px;
  margin-top: -20px;
}
@media screen and (max-width: 650px) {
  .checklist-form-card {
    margin: 10px;
  }
  .checklist-form-list {
    display: block;
    width: 100%;
  }
  .save-and-delete-buttons {
    margin: 40px;
    margin-top: 0px;
  }
}
