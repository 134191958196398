.ant-drawer-close-reservation-x {
  position: relative !important;
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}

.booking-confirmation-code-row {
  padding: 5px;
  text-align: center;
}

.saveButton {
  text-align: center;
  margin: auto;
}

.addButton {
  margin-top: 20px;
  margin-left: 110px;
}

.booking-confirmation-code {
  font-weight: bold;
  font-size: 32px;
  word-wrap: break-word;
}

.reservationDetailsContainer {
  width: 100%;
}

.paymentDetails {
  background-color: #e0e7e7;
  padding: 15px;
}

.transactionDetails {
  margin: 40px 0px 0px 0px;
  background-color: #e0e7e7;
  padding: 15px;
}

.reservationPaymentContainer {
  margin-top: 60px;
  padding-right: 30px;
  width: 30%;
}

.sideBySide {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 10px;
}

.sideBySideItem {
  display: flex;
  flex-direction: column;
  width: 49.5%;
  margin: 0px 3px;
}

.formRow {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebySide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingFormRadio {
  width: 125px;
  margin: 3px;
}

.downloadPdfButton {
  float: right;
}

.bookingFormPayment {
  text-align: center;
  margin: auto;
  border-bottom: 1px solid #e0e7e7;
  background-color: white;
  padding: 30px 0 30px 0;
}

.bookingFormPaymentBottom {
  text-align: center;
  margin: auto;
  background-color: white;
  padding: 30px;
}

.bookingFormPayment > .ant-col-24 > .ant-btn {
  width: 100%;
}

.check-in {
  font-weight: bold;
  font-size: 25px;
  word-wrap: break-word;
}

.divider {
  margin: 16px auto;
  width: 30%;
  border-bottom: 1px solid #e0e7e7;
}

.reviewDivider {
  margin: 16px auto;
  width: 90%;
  border-bottom: 2px solid #e0e7e7;
}

.totalAmount {
  padding-bottom: 10px;
}

.check-out {
  font-weight: bold;
  font-size: 25px;
  word-wrap: break-word;
}

.securityDepositSection {
  text-align: center;
  margin: auto;
  background-color: white;
  padding: 30px 0 0 0;
}

.securityDepositItem {
  margin-bottom: 20px;
}

.errorMsg {
  color: red;
}

.split {
  display: flex;
}

.payment-buttons {
  width: 100%;
  margin-bottom: 10px;
}

.reservation-card {
  margin-bottom: 16px;
}

.rf-buttons-bottom {
  display: none;
}

.rf-buttons-center {
  margin-top: 12px;
}

.whatsapp-button {
  background-color: #25d366;
  border-color: #25d366;
  border-radius: 4px;
}

.whatsapp-button:hover,
.whatsapp-button:focus {
  background-color: rgb(72, 194, 117);
  border-color: rgb(72, 194, 117);
}

.whatsapp-button:active {
  background-color: rgb(25, 145, 69);
  border-color: rgb(25, 145, 69);
}

.activityLogDiff {
  background-color: #ffffe0;
  font-weight: 600;
}

@media (max-width: 768px) {
  .reservationPaymentContainer {
    margin-top: 60px;
    padding-right: 30px;
    width: 30%;
    display: none;
  }
  .rf-buttons-bottom {
    display: inline;
  }
  .rf-buttons-center {
    display: none;
  }
  .sideBySide {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .sideBySideItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px 3px;
  }
}
