.ant-layout {
  min-height: 100vh;
  background-color: #eeeeee0a;
}

.ant-layout-sider-zero-width-trigger:hover {
  background: transparent;
}

.ant-layout-sider-zero-width-trigger {
  height: 32px;
  top: 13px;
  right: -52px;
  color: #fff;
}

.anticon-bars {
  font-size: 24px;
}

.layout {
  overflow-x: auto;
}
