.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
  width: 100%;
}

.container * {
  margin-bottom: 24px;
}

.title {
  font-size: 24px;
  line-height: 32px;
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
}

.link {
  font-size: 14px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .title {
    font-size: 30px;
    line-height: 38px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 28px;
  }

  .link {
    font-size: 16px;
    line-height: 24px;
  }
}
