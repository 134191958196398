.drawerOpen > div {
  transition: width 0.5s !important;
  transition-timing-function: ease-out;
}

.pricingRule {
  font-size: 13px;
}

.addNewRule {
  font-style: italic;
  font-weight: 300;
  color: rgb(99, 99, 99);
}

.confirmDate {
  color: rgb(0, 206, 221);
}
