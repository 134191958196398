.root {
  width: 100%;
  line-height: 40px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  font-size: 14px;
  width: 30%;
  min-width: 30px;
  max-width: 60px;
}

.text {
  margin: 0;
  font-size: 16px;
}
