.root {
  padding: 20px 0;
}

.rightContainer {
  text-align: center;
}

.imageContainer {
  margin-bottom: 20px;
}

.image {
  width: 100%;
}

.stepCardContainer {
  margin: 50px 0;
}
.stepCardContainer:last-child {
  margin: 0;
}

.stepCardLabel {
  color: #bdbdbd;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.stepCardProgressBar {
  margin-bottom: 10px;
}

.stepCardSelection {
  width: 160px;
}

.stepCardSelectionNoCreate {
  width: 250px;
}

.stepCardDivider {
  font-size: 24px;
}

.stepCardLink {
  font-size: 12px;
}

@media (min-width: 768px) {
  .stepCardSelection {
    width: 300px;
  }
}
