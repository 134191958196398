.form-row {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}
.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eu-buttonrow {
  margin-top: 10px;
}

.ota-card {
  margin-bottom: 20px;
  width: 97.5%;
}

.ota-label {
  line-height: 40px;
}

.ota-row {
  margin-bottom: 24px;
}

.ota-action-button-row {
  width: 100%;
}

.full-length-box {
  width: 97.5%;
}

.scroll-bar-style {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
}
