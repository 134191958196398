.amountReceived {
  color: green;
  margin: auto;
  width: 70%;
  font-weight: bold;
  text-align: right;
}

.amountCharged {
  color: red;
  margin: auto;
  width: 70%;
  font-weight: bold;
  text-align: right;
}

.originalAmount {
  color: #c0c0c0;
  margin: auto;
  width: 70%;
  font-weight: bold;
  text-align: right;
}

.actionIcon {
  color: #00b9c6;
  font-size: 16px;
  cursor: pointer;
}

.actionIconDisabled {
  color: #474747;
  cursor: not-allowed;
}

.centerText {
  text-align: center;
}

.rightText {
  text-align: right;
}

.scrollableDiv {
  overflow: auto;
  height: 300px;
}

.dailyBreakdownSaveBtn {
  margin-top: 10px;
  width: 70%;
}
