.modalMainItem {
  margin-bottom: 12px;
}

.modalMainButton {
  width: 100%;
}

.modalBlockDatesRemarks {
  margin-bottom: 15px;
}

.modalBlockDatesButtons {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 24px;
}

@media (min-width: 768px) {
  .modalMainItem {
    margin-bottom: 0;
  }
}
