.reservationPlatformColor {
  color: #00b9c6;
}

.reservationCodeColor {
  color: #289d48;
}

.targetUnitNameColor {
  color: #00b9c6;
}

.warningColor {
  color: #f5461b;
}

.confirmMovingReservationRangePicker {
  padding: 20px 30px 0px 0px;
}
