.formTitleContainer {
  width: 100%;
  margin: 38px 0;
}

.formItemContainer {
  width: 100%;
  margin-bottom: 38px;
}

.formTitle {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
}
