.collapse {
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 0 !important;
  border-bottom: 1px solid #c9c9c9;
}

.collapse div {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.collapse > div:first-child {
  padding: 0 0 0 40px !important;
}

.collapse:hover {
  background-color: #cccccc;
}

.collapseContent {
  margin-top: -1px;
}

.collapseIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapseRow {
  color: rgba(0, 0, 0, 0.65);
}

.collapseRowLeftCol {
  font-size: 90%;
}

.collapseRowRightCol {
  border-left: 1px solid #c9c9c9;
}

.ratesBox {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  font-size: 11px;
  font-weight: normal;
  background-color: #fafafa;
}

.rate {
  box-sizing: border-box;
  height: 25px;
  line-height: 25px;
  border-top: 0.5px solid #c9c9c9;
}

/* ------------------Room Type------------------------ */
.roomType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  margin-right: 4px;
}

/* ------------------Inventory------------------------ */
.inventory {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  background-color: #e3e3e3;
}

/* -----------------------Unit------------------------ */
.unitCollapseRow {
  margin-right: -16px;
  margin-left: 24px;
}

.unitName {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  margin-left: -40px;
  font-size: 80%;
  border-top: 0.5px solid #c9c9c9;
}
