.content {
  background-color: #eeeeee0a;
  overflow-x: auto;
  min-height: 80vh;
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
}
