.bedRoomListItemButtonContainer * {
  margin-right: 10px;
}

.bedRoomListItemButtonContainer :last-child {
  margin-right: 0;
}

.listArrangementDetails {
  margin: 6px 0;
}
