.payoutDetailsBanner {
  background-color: rgb(231, 253, 255);
  padding: 15px;
  margin-bottom: 20px;
}

.payoutDetailsBannerTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
}

.payoutDetailsBannerContent {
  display: flex;
  justify-content: center;
}

.payoutDetailsBannerAmount {
  color: #00b9c6;
  font-size: 32px;
}

.payoutDetailsBannerNoAmount {
  color: #00b9c6;
  font-size: 20px;
  font-weight: 600;
}
