.el-label {
  font-weight: bold;
}

.el-value {
  padding-left: 1%;
  text-align: right;
  word-wrap: break-word;
}

.el-card {
  margin: 10px;
  width: 100%;
  min-width: 250px;
}

.el-button {
  margin: 15px auto 0 auto;
  width: 100px;
}
