.toggleTitle {
  padding-top: 16px;
}

.fullLengthTextBox {
  width: 97.5%;
}

.halfLengthTextBox {
  width: 47.5%;
}

.quarterLengthTextBox {
  width: 53.5%;
}

.shortLengthTextBox {
  width: 15%;
}

.scrollBarStyle {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
}
