body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.list-card {
  margin: 30px;
  min-width: 600px;
}

.list-card-control-section {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
