.login-card {
  box-shadow: 0px 0px 100px -20px rgba(61, 61, 61, 0.63);
}

.login-page > main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form-lang {
  float: right;
}

.login-hp-logo {
  width: 300px;
  height: 100%;
}
