.cardContainer {
  margin-bottom: 16px;
}

.rateAdjustmentLabel {
  cursor: default;
  color: rgba(0, 0, 0, 0.85);
  line-height: 39.9999px;
}

.rateAdjustmentLabelAsterisk {
  margin-right: 4px;
  font-family: SimSun;
  color: #f5222d;
}

.info {
  margin-top: -10px;
  margin-bottom: -10px;

  font-style: italic;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
