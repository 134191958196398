.sp-form-card {
  margin: 30px;
}

.sp-expenses-item {
  margin-bottom: 0px;
}

.sp-expenses-item:last-child {
  margin-bottom: 24px;
}

.sp-button-margin {
  margin-left: 2px;
}

.sp-input {
  width: 100%;
}

.sp-icon {
  margin-left: 4px;
}

.sp-fees-row {
  margin-top: 30px;
}

.slider-fit {
  margin-right: 17px;
  margin-left: 17px;
}
.slider-padding {
  padding: 16px;
}
.detele-and-save-btn {
  margin: 40px;
  margin-top: -20px;
}

.start-day {
  margin-bottom: -5px;
  width: 90px;
}

.start-day-message {
  color: #00b9c6;
  font-size: small;
}

.bold-day {
  font-weight: bold;
}

.payout-configuration-tooltips {
  max-width: 50vw;
}
