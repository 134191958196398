.label {
  margin-bottom: 8px;
  text-transform: capitalize;
}

.large {
  width: '100%';
  font-size: '14px';
}

.colorCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
