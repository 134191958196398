.subValue {
  font-size: 20px;
  color: #b5b5b5;
}

.mainValue {
  font-size: 200%;
  font-weight: 700;
}
.pg-summary {
  margin-top: 8px;
  margin-bottom: 24px;
}

.pg-card-font {
  text-align: center;
  height: 100%;
}

.pg-card-body {
  height: 100%;
  word-break: break-word;
}

.statisticTitle {
  font-size: 120%;
  text-align: left;
}

.pg-container {
  width: 90%;
}

@media screen and (max-width: 1200px) {
  .pg-graph {
    margin-bottom: 50px;
  }
}
