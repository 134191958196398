#CICODaysCheckIn,
#CICODaysCheckOut {
  display: flex;
  flex-direction: column;
}

#CICODaysCheckIn > label,
#CICODaysCheckOut > label {
  margin-bottom: 8px;
}
