.reservationBoxes {
  position: absolute;
  margin-top: 8px;
  height: 35px;
  border: 1px solid #e3e3e3;
  z-index: 1;
}

.reservationThumbnail {
  overflow: hidden;
  top: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  transition: ease-in-out 0.1s;
}

.reservationThumbnailText {
  top: 1px;
  position: relative;
}

.reservationThumbnail:hover {
  /* remove this !important when the moveDownPx is removed or updated */
  top: -3px !important;
}

.avatar {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  margin-left: 1px;
  margin-top: 1px;
}

.avatarColor {
  background-color: white;
}

.avatarColorNonProfit {
  background-color: #00000050;
}

.blockLabel {
  line-height: 40px;
}

.blockRow {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}
