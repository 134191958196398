.booking-form-item {
  margin-bottom: 10px;
}

.guest-input {
  width: 72%;
  margin: auto;
  padding-top: 5px;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.split {
  display: flex;
}
