.csvBtn {
  margin-bottom: 24px;
}

.downloadBtn {
  background-color: #00b9c6;
  border: none;
  border-radius: 3px;
  height: 40px;
  font-size: 14px;
  color: #fff;
  margin: 4px;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  float: right !important;
  top: 65px;
}
.calNextBtn {
  font-size: 100%;
  color: white;
  border-radius: 18px;
  background-color: #00b9c6;
  border-color: transparent;
  margin: 5px;
  height: 32px;
  width: 32px;
}
.card-font {
  text-align: center;
  font-size: 0.8vw;
}

.rep-container {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 12px 0 12px;
}
.rep-title {
  margin-top: 1%;
  margin-bottom: 24px;
  padding-right: 12px;
  padding-left: 12px;
}
.rep-card {
  margin-bottom: 8px;
}
.select {
  width: 40%;
}
.textalignLeft {
  text-align: left;
}
.reportingcurrencyFont {
  font-size: 180%;
  position: relative;
  top: -4px;
  color: #b5b5b5;
  padding-right: 5px;
}
.reportingValues {
  font-size: 220%;
  font-weight: 700;
}
.reportingSubvalue {
  font-size: 20px;
  color: #b5b5b5;
}
.customPadding {
  padding-bottom: 50px;
}
.rep-tab-pane {
  display: flex;
  justify-content: center;
}
.selectStyling {
  text-align: right;
  padding-top: 10px;
  margin-right: 30px;
}
.datechangerPadding {
  padding-right: 6%;
  padding-left: 6%;
  width: 100%;
}
.rep-title {
  margin-top: 30px;
}
.rep-wrapper {
  margin-top: 5px;
  margin-left: 5px;
}

@media only screen and (max-width: 1200px) {
  .custom-row {
    padding-bottom: 15px;
    padding-top: 15px;
    padding-right: 6%;
    padding-left: 6%;
    flex-direction: column;
    display: flex;
  }
  .card-font {
    text-align: center;
    font-size: 2vw;
  }
  .downloadBtn {
    background-color: #00b9c6;
    border: none;
    border-radius: 3px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    margin: 4px;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .datechangerPadding {
    margin-top: -29px;
    width: 100%;
  }
  .graphMargin {
    margin-bottom: 50px;
  }
}

.no-data-reporting-container {
  color: #bdbdbd;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 520px) {
  .rep-wrapper {
    margin-left: 0;
  }

  .rep-title {
    padding: 0px 12px 0px 0px;
  }
}

@media only screen and (max-width: 425px) {
  .gutterWidth {
    min-width: 300px;
  }
}
