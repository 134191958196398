.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
}

.login-form > div:last-of-type {
  margin-bottom: 0;
}
