.title {
  margin: -24px; /* the background won't filled the whole panel without this margin */
  padding: 24px;

  color: white;
  font-size: 24px;
  font-weight: bold;
}

.details {
  margin: -24px;
  margin-bottom: auto;
  padding: 25px 0px 15px 25px;
  justify-content: left;
  display: flex;
  flex-flow: column wrap;
  /* max-height: 250px; */
  max-height: 340px;
  max-width: 480px;
}

.detail {
  width: 50%;
  padding: 5px 8px 5px 0;
}

.detailIcon {
  font-size: 16px;
  padding-right: 20px;
  font-size: 20px;
  color: #9e9e9e;
}
.detailDisplay {
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: break-word;
}
.detailResDisplay {
  font-size: 14px;
  font-weight: 400;
}

.unblockButton {
  float: left;
}

.buttons {
  float: right;
}

.modalButtons {
  font-size: 24px;
  margin: 0px 0px 10px 0px;
}

.cancelButton {
  font-size: 24px;
  height: 100%;
  position: relative;
  justify-content: end;
  bottom: 0;
}

.vl {
  border-left: 1px solid rgba(128, 128, 128, 0.5);
  height: 100vh;
  min-height: 200px;
  max-height: 300px;
  position: relative;
  bottom: 0;
}

.allButtons {
  /* border: 5px solid black; */
  min-height: 275px;
  max-height: 280px;
  position: relative;
  bottom: 0;
}
