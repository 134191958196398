.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.textLabel {
  font-weight: bold;
  font-size: 20px;
  color: white;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-right: 8px;
}
