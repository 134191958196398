.dragBoxContainer {
  width: 200px !important;
  height: 35px !important;
  border: 0px #d9d9d9 !important;
  cursor: default !important;
  border-radius: 4px !important;
  text-align: center !important;
  margin: auto;
}

.fileContainer {
  margin-bottom: 36px;
}

.fileList {
  text-align: center;
}

.delete {
  margin-left: 8px;
}
