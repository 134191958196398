.full {
  border-left: 0;
  border-right: 0;
}

.end {
  border-radius: 0 0 250px 0;
  border-left: 0;
  width: 40%;
  float: left;
}

.start {
  border-radius: 250px 0 0 0;
  border-right: 0;
  width: 40%;
  float: right;
}

.blocked {
  background-color: #91919180;
}

.booked {
  background-color: #289d47b4;
  box-shadow: 0px 2px green;
}

.extraHeight {
  height: 25px;
  margin-bottom: 8px;
}

.ant-fullcalendar-date {
  height: 30px !important;
}

.collapseHead {
  display: flex;
  align-items: center;
  margin-left: 1%;
  font-size: 14px;
}

.ant-fullcalendar-cell {
  padding: 0;
}

.collapseTitle {
  margin-left: 1%;
}

.payoutcalendar .ant-radio-group,
.payoutcalendar .ant-fullcalendar-header {
  display: none;
}

.payoutcalendar .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: transparent;
}

.payoutcalendar .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: transparent;
}

.payoutcalendar .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.65);
}

.payoutcalendar .ant-fullcalendar-value:active {
  background: transparent;
  color: rgba(0, 0, 0, 0.65);
}

.payoutcalendar .ant-fullcalendar-value:hover {
  cursor: default;
}
