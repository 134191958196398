.el-hostSelect {
  width: 200px;
}

.el-title {
  margin-top: 30px;
  padding-right: 30px;
}

.col-wrapper {
  margin-top: 5px;
}

.el-pageTitle {
  font-weight: bold;
  font-size: 15px;
}

.el-cardAlign {
  align-content: center;
  margin: 30px;
  margin-top: 0px;
}

@media (max-width: 768px) {
  .el-cardAlign {
    margin-top: 0px;
    padding-right: 10px;
  }
  .el-title {
    padding-right: 50px;
  }
}
