.notes {
  background-color: #cbf2f436;
  border-color: #cbf2f4;
}

.notes i {
  color: #009da8 !important;
}

.notesText {
  margin-bottom: 0;
  color: #757575;
}
