.user-profile-card {
  min-width: 100px;
  margin: 30px;
}
.user-profile-image {
  display: block;
  margin: auto;
  max-inline-size: 75%;
}
.user-profile-name {
  font-weight: bold;
  font-size: 30px;
  word-break: break-all;
  word-wrap: break-word;
  text-align: left;
}
.user-profile-buttons {
  margin-top: 12px;
}
@media (max-width: 768px) {
  .user-details-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .user-detail-row {
    width: 100%;
    word-wrap: break-word;
  }
  .user-detail-field-name {
    text-align: right;
  }
  .user-profile-buttons-row {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .user-profile-name {
    text-align: center;
  }
}
