.derivedRateInfoContainer {
  margin-top: 8px;
}

.disclaimerText {
  font-size: 12px;
  color: gray;
}

.viewMoreLink {
  color: #00b9c6;
  cursor: pointer;
}

.warningLabel {
  font-size: 12px;
  color: gray;
}
