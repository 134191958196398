.notes {
  background-color: #cbf2f436;
  border-color: #cbf2f4;
}

.notes i {
  color: #009da8 !important;
}

.notesText {
  margin-bottom: 0;
  color: #757575;
}
.selector {
  width: 100%;
  float: left;
  margin-right: 8px;
}
.hostRelationshipCard {
  min-width: 352px;
  margin-bottom: 16px;
  overflow-x: auto;
  margin: 40px;
}

@media (max-width: 768px) {
  .selector {
    margin-right: 0px;
    margin-bottom: 8px;
  }
}

@media (max-width: 320px) {
  .selector {
    width: 175px;
  }
}
