.formTitleContainer {
  width: 100%;
}

.formTitle {
  color: #000000;
  font-size: 20px;
}

.formItemContainer {
  width: 100%;
}

.timepickerInput {
  font-size: 14px;
  width: 100%;
}

.formButtonWrap {
  display: block;
  align-content: center;
}
