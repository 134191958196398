.guest-input {
  width: 72%;
  margin: auto;
}

.booking-form-danger > .ant-btn-danger {
  margin-bottom: 30px;
  margin-top: 30px;
}

.submitButton {
  margin: auto;
}
.split {
  display: flex;
}
