.roomCard {
  width: 97.5%;
  margin-bottom: 25px;
}

.roomCard:last-of-type {
  margin-bottom: 0;
}

.scrollBarStyle {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 65vh;
}
