.header {
  margin: 30px 0 0 30px;
}

.buttonCSV {
  float: right;
}

.table {
  margin-top: 20px;
}

.rateName {
  color: #00b9c6;
  cursor: pointer;
}

.remarks {
  word-break: break-all !important;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterDropdown {
  padding: 8px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.filterDropdownInput {
  width: 150px;
  margin-right: 8px;
}

.filterDropdownCheckboxGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  user-select: none;
  padding: 6px;
  margin-bottom: 6px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.filterDropdownCheckboxGroup label {
  width: 100%;
  padding: 4px;
}

.filterDropdownButtonSearch {
  margin-right: 4px;
}
