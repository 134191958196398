.formTitleContainer {
  width: 100%;
  margin: 38px 0;
}

.formTitle {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
}

.formItemContainer {
  width: 100%;
  margin-bottom: 38px;
}

.notes {
  background-color: #cbf2f436;
  border-color: #cbf2f4;
}

.notes i {
  color: #009da8 !important;
}
