.card {
  min-width: 1225px;
}

.leftCol {
  min-width: 200px;
}

.rightCol {
  overflow-x: scroll;
  overflow-y: hidden;
  border-left: 1px solid #c9c9c9;
}

.monthRow {
  height: 80px;
  display: flex;
  font-weight: bold;
  text-align: center;
}

.monthRowSticky {
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #c9c9c9;
}

.monthRowStickyDateRow {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
}

.monthRowStickyMonthHeader {
  border-right: 1px solid #c9c9c9;
}

.monthHeader {
  display: grid;
  background-color: #fafafa;
}

.monthDateRow {
  width: max-content;
}

.verticalPropertyText {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #e3e3e3;
  font-weight: bold;
  font-size: 15px;
  padding-top: 5px;
  -webkit-transition: 0.2s ease-out; /* For Safari 3.1 to 6.0 */
  transition: 0.2s ease-out;
}

.mcCollapse {
  border-radius: 0;
}

.mcCollapsePropertyAllContainer {
  border-top: 1px solid #c9c9c9;
}

.mcCollapsePropertyAll {
  border: 0;
  border-left: 1px solid #c9c9c9;
}

.warningOnClashingReservationIcon {
  color: red;
  margin-right: 10px;
}

/* --------------------- Reservations Color ----------------------- */
.bookingStatusConfirm {
  background-color: #d5ecdb;
  border-color: #59d37e;
}

.bookingStatusConfirmModal {
  background-color: #59d37e;
}

.bookingStatusAwaitingPayment {
  background-color: #b0e0e6;
  border-color: #00bfff;
}

.bookingStatusAwaitingPaymentModal {
  background-color: #00bfff;
}

.bookingStatusCheckIn {
  background-color: #ffd27f;
  border-color: #ff4500;
}

.bookingStatusCheckOut {
  background-color: #f08080;
  border-color: #dc143c;
}

.bookingStatusReserved {
  background-color: #cccc00;
  border-color: #9b9b1e;
}

.bookingStatusNoShow {
  /* background-color: #c5a0e2;
  border-color: #a012ff; */
  /* background-color: #bcbbc1; */
  /* background-color: rgba(188, 187, 193, 0.7);
  border-color: #9e9e9e; */
  background-color: rgba(242, 102, 155, 0.7);
  border-color: #e0115f;
}

.bookingStatusNoShowModal {
  /* background-color: #bcbbc1; */
  background-color: #f2669b;
}

.bookingStatusVacantDirty {
  /* background-color: #ffc2d4; */
  /* border-color: #ff9ebb; */
  background-color: #4c9404;
  border-color: #3e7a02;
}

.bookingStatusVacantClean {
  background-color: #dbfeb8;
  border-color: #96e072;
}

.bookingStatusVacantCleanModal {
  background-color: #96e072;
}

.bookingTypeFreeStay {
  /* background-color: #ffa9f8; */
  background-color: rgba(255, 169, 248, 0.7);
  border-color: #ff12ff;
}

.bookingTypeFreeStayModal {
  background-color: #ff12ff;
}

.bookingTypeMaintenanceAndBlock {
  background: repeating-linear-gradient(-60deg, #d4d4d4 0, #d4d4d4 10px, #e0e0e0 0, #e0e0e0 20px);
  border-color: #bdbdbd;
}

.bookingTypeCancellation {
  /* background-color: #e30b5c; */
  background-color: rgba(227, 11, 92, 0.7);
  border-color: #e0115f;
}

.bookingTypeCancellationModal {
  background-color: #e30b5c;
}

.noBookingStatus {
  background-color: #ffffff;
}
/* --------------------- Reservations Color ----------------------- */
