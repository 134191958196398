.dateBlock {
  padding-top: 20px;
  color: grey;
  border-color: #c9c9c9;
  background-color: #fafafa;
}

.dateBlockToday {
  background-color: #e6fffc;
  color: #0db6b9;
}

.dateBlockSunday {
  color: red;
}
