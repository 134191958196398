.unitTab {
  border: 0;
  margin-bottom: 0;
}

.unitTabPane {
  background-color: #cbf2f41a;
  border: 1px solid #eeeeee;
  padding: 16px;
  margin-bottom: 16px;
  overflow-x: auto;
}

.cardContainer {
  margin-bottom: 16px;
}
