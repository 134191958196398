.label {
  margin-bottom: 8px;
  text-transform: capitalize;
}

.large {
  width: '100%';
  font-size: '14px';
}

.selectionLabel {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.selectionDefaultStyle {
  width: 200px;
}

.legendBox {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  border-radius: 3px;
}

.legendBoxRow {
  display: flex;
  align-items: center;
  padding: 0 5px;
}
