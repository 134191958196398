.hostProtect {
  padding: 14px 32px 14px 32px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  margin-bottom: 10px;
}

.hostProtected {
  background: #d5ecdb;
}

.hostUnProtected {
  background: #ffc8c8;
}

.hostProtectedTitle {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}
