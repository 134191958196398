.eu-cards .ant-card-body {
  min-height: 300px;
  font-size: 14px;
  line-height: 1.9;
}

.eu-inputs {
  width: 150px;
}

.eu-tabTitles {
  font-size: 14px;
}

.eu-inputLabel {
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
}

.eu-inputRow {
  margin: 15px 0;
}

.gap {
  margin-bottom: 1%;
}

.eu-toplabel {
  font-weight: bold;
}

.eu-top-description {
  word-wrap: break-word;
}

.eu-savebutton {
  margin-left: 10px;
}

.eu-buttonrow {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .eu-cards-first .ant-card-body {
    height: 130px !important;
  }

  .eu-cards-first {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 430px) {
  .eu-cards .ant-card-body {
    height: 220px;
    font-size: 11px;
    padding: 10px !important;
    vertical-align: middle;
  }

  .eu-cards-first .ant-card-body {
    height: 80px !important;
  }

  .eu-inputRow {
    margin: 5% 0;
  }

  .eu-inputRow label,
  .eu-inputRow input {
    font-size: 11px;
    font-weight: bold;
  }

  .fieldLabel {
    width: 50px;
  }
}
