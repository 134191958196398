.userprofile-form-list {
  padding: 0px 18px;
  display: block;
  width: 100%;
}

.userprofile-button-wrapper {
  display: block;
  width: 100%;
  padding: 0px 18px;
}

.user-profile-passsword-form-card {
  margin: 30px;
  min-width: 260px;
}
