.tableOTASourceLogo {
  margin-right: 8px;
}

.actionButtons {
  width: 144px;
}

.actionButtonSeasonalRule {
  width: 100%;
  margin: 4px;
}

.actionButtonsEditDelete {
  width: 100%;
  margin: 4px;
  display: flex;
  justify-content: space-between;
}

.buttonNewIntegration {
  margin-top: 18px;
  width: 100%;
}
