.selectionRow {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin-bottom: 16px;
}

.selectionListing {
  width: 49.5%;
}

.selectionHotel {
  width: 100%;
}

.selectionBody {
  margin-top: 4px;
  width: 100%;
}
