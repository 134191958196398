.row {
  display: flex;
  cursor: pointer;
}

.rateDayDefaultBackgroundColorPast {
  background-color: rgb(225, 225, 225);
}

.rateDayDefaultBackgroundColor {
  background-color: #fafafa;
}

.rateDayClasedReservation {
  background: repeating-linear-gradient(-45deg, red 0, red 10px, #ffffff 0, #ffffff 20px);
}

.hoveredHighlight {
  background-color: lightgrey;
}

.hoveredHighlightClashed {
  background-color: red !important;
}
