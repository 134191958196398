.signup-form-forgot {
  float: right;
}

.signup-form-button {
  width: 100%;
}

.signup-form > div:last-of-type {
  margin-bottom: 0;
}
