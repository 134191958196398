.label {
  font-weight: bold;
  font-size: 14px;
}

.select {
  width: 100%;
  margin-top: 8px;
}

.currentRoomTypeLabel {
  margin-bottom: 15px;
}

.alert {
  margin-top: 30px;
}
