.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adminPanelButton {
  margin-left: 32px;
}

.announcement-button {
  margin-right: 20px;
  margin-top: 8px;
}

.announcement-button-icon {
  cursor: pointer;
  font-size: 24px;
  color: white;
  transition: filter ease-in-out 0.1s;
}

.announcement-button-icon:hover {
  filter: brightness(80%);
}

.announcement-post > p:first-of-type > img:first-of-type {
  /* For hero image in markdown */
  width: 100%;
}

.header-firstname {
  color: white;
}

.ant-layout-header {
  padding: 0 20px;
  background-image: url('../../images/has-header-bar.png');
  background-position: right;
}

.booking-engine-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b6cd;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  color: #e6fffc;
  box-shadow: 0px 2px 4px #607d8bad;
  margin-right: 10px;
}

.booking-engine-icon:hover {
  background-color: #cccccc;
}

.notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b6cd;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  color: #ffffff;
  box-shadow: 0px 2px 4px #607d8bad;
  margin-right: 10px;
}

.notifications:hover {
  background-color: #cccccc;
}

img.avatar {
  margin-right: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  filter: brightness(95%);
}

img.avatar:hover {
  filter: brightness(100%);
}

@media screen and (max-width: 450px) {
  .no-show {
    display: none;
  }
}
