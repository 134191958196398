.marginBottom {
  margin-bottom: 16px;
}

.checkbox {
  margin-right: 8px;
}

.amount {
  font-size: 24px;
}
