.taskDetailsContainer {
  width: 100%;
}

.details-card {
  margin-bottom: 16px;
}

.inputaddon .ant-input-group-addon:last-child {
  background-color: #00b9c6;
}

.activityLogDiff {
  background-color: #ffffe0;
  font-weight: 600;
}