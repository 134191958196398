.actionIcon {
  color: #00b9c6;
  font-size: 16px;
  cursor: pointer;
}

.actionIconDisabled {
  color: rgba(0, 0, 0, 0.15);
  font-size: 16px;
  cursor: not-allowed !important;
}

.hostSelectTitle {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.hostSelects {
  width: 200px;
}

.billingCard {
  margin: 30px;
  min-width: 950px;
}

.warningOverdueInvoice {
  color: red;
  margin-right: 10px;
}
