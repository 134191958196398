.trxnTab {
  border: 0;
  margin-bottom: 0;
}

.trxnTabPane {
  background-color: #cbf2f41a;
  border: 1px solid #eeeeee;
  padding: 16px;
  margin-bottom: 16px;
}

.cardContainer {
  margin-bottom: 16px;
}
