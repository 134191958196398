.rateBlockPerDay {
  position: relative;
}

.rateBlockPerDay:hover {
  background-color: lightgrey;
  transition: 0.4s;
}

.rateBlock {
  display: flex;
  justify-content: center;
  border-style: solid;
  border-width: 0.5px 0 0 0.5px;
  border-color: #c9c9c9;
  font-size: 11px;
}

.inventoryBox {
  font-weight: bold;
  font-size: 12px;
  background-color: #e3e3e3;
}

.inventoryDetailBox {
  height: 25px;
  line-height: 25px;
  border-top: 1px solid #d9d9d9;
}

.onDragReservation {
  opacity: 0.5;
  position: absolute;
  z-index: 1;
}

.onDragReservationHighlight {
  background: #00b9c6;
}

.onDragReservationHighlightActionBlocked {
  background: repeating-linear-gradient(-45deg, #bdbdbd 0, #bdbdbd 10px, #e0e0e0 0, #e0e0e0 20px);
}

.onDragReservationHighlightClashed {
  background: red;
}
