.rateInput {
  margin-bottom: 0;
}

.checkboxSelection {
  margin-bottom: 24px;
}

.derivedRateInfoContainer {
  margin-top: 8px;
}
