.ClashingReservationWarningBanner {
  margin-bottom: 25px;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 10px 0 5px;
}

.filterRow {
  display: flex;
  align-items: center;
}

.selectionHost {
  width: 200px;
  margin-right: 10px;
}

.selectionProperty {
  width: 200px;
  margin-right: 10px;
}

.selectionStatus {
  /* width: 150px; */
  /* width: 500px; */
  width: 99%;
  margin-right: 10px;
  margin-bottom: 20px;
}

.secondRow {
  margin-bottom: 15px;
  padding: 0 10px 0 5px;
}

.bookingStatusRow {
  display: flex;
  justify-content: space-between;
}
