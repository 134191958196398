.booking-number {
  font-size: 25px;
  font-weight: bold;
  word-break: break-word;
}

.booking-form-form {
  padding: 40px 0px 0px 0px;
}

.booking-form-arrangement {
  display: flex;
  flex-direction: row;
  padding: 0 32px;
}

.booking-refund {
  margin-bottom: 20px;
}

.paymentStyle {
  border-left: 6px solid red;
}

.paymentContainer {
  border: 1px solid #e0e7e7;
  background-color: white;
  padding: 15px;
  margin: 10px;
}

.insideContainer {
  margin: 10px;
}

.stepsBox {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e7e7;
  font-weight: bold;
  padding: 32px;
  background: #ffffff;
}

.bookingFormPaymentDetails {
  background-color: #e0e7e7;
  padding: 15px;
}
.booking-confirmation-code-row {
  background-color: #e0e7e7;
  padding: 5px;
}

.booking-form-description {
  margin: 10px auto auto auto;
  width: 90%;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e7e7;
  font-weight: bold;
  background: #ffffff;
}

.discount-price {
  font-size: 12px;
  color: #00000073;
}

.original-price {
  text-decoration: line-through;
}

.booking-label {
  line-height: 40px;
}

.booking-form-payment-description {
  margin: auto;
  width: 100%;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e7e7;
  font-weight: bold;
  background: #ffffff;
}

.booking-form-container {
  margin: auto;
  width: 90%;
  border: 1px solid #e0e7e7;
  background-color: white;
  padding: 15px;
}

.booking-form-payment {
  text-align: center;
  margin: auto;
  border-bottom: 1px solid #e7e9e9;
  background-color: white;
  padding: 30px 0 30px 0;
}

.booking-form-payment > .ant-col-24 > .ant-btn {
  width: 100%;
}

.booking-form-locations {
  margin: auto;
  width: 90%;
  background-color: #e0e7e7;
  padding: 15px;
}

.date-picker {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 10px;
}

.date-picker-item {
  display: flex;
  flex-direction: column;
  width: 49.5%;
}

.form-row {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-bottom: 24px; */
}

.steps-action {
  margin: auto;
  padding: 15px;
  text-align: center;
}

.booking-form-steps-toggle {
  margin: 0px 4px;
}

.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-bottom: 10px; */
}

.side-by-side > * {
  width: 49.5%;
  margin: 0px 3px;
}

.ant-switch-checked {
  background: #289d48;
}

.booking-button {
  width: 100%;
}

.bottom-divider {
  border-bottom: 1px solid #e0e7e7;
  margin: 16px auto;
  width: 30%;
}

.booking-number-bottom {
  font-weight: bold;
  font-size: 25px;
  word-wrap: break-word;
}

.booking-form-payment > .ant-col-24 > input.ant-input {
  width: 70%;
}

.booking-confirmation-code {
  font-weight: bold;
  font-size: 32px;
}

.icon-square {
  color: #00b9c6;
}

.icon-eye {
  color: #00b9c6;
}

.icon-calendar {
  color: #00b9c6;
}
.steps-action-1 {
  display: none;
}

.booking-form-left {
  margin-right: 16px;
}

.booking-form-right {
  margin-left: 16px;
}
.steps-action-payment-details {
  display: none;
}

@media (max-width: 768px) {
  .steps-action-payment-details {
    display: block;
  }

  .steps-action {
    display: none;
  }

  .booking-form-arrangement {
    display: flex;
    flex-direction: column;
  }

  .booking-form-form {
    padding: 40px 0px 0px 0px;
    width: 100%;
  }

  .booking-form-right {
    position: relative;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
    padding-bottom: 15px;
  }

  .booking-form-payment {
    flex-direction: row;
    display: flex;
  }

  .bottom-divider {
    visibility: hidden;
  }
  .responsive-payment-details {
    width: 100%;
  }

  .responsive-middle-details {
    width: 100%;
    flex-direction: row;
    display: flex;
    padding-top: 10px;
  }
  .responsive-booking-form-payment {
    flex-direction: column;
    display: flex;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .booking-form-payment {
    flex-direction: column;
    display: flex;
  }
  .deposit-styles {
    display: inline;
  }
  .security-deposit-collected-style {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}

@media (max-width: 1090px) {
  .date-picker {
    flex-direction: column;
  }

  .date-picker-item {
    width: 100% !important;
  }

  .side-by-side {
    flex-direction: column !important;
  }

  .checkboxesContainer {
    width: 100% !important;
    height: 120px;
  }

  .calculationContainer {
    border-right-style: none;
    border-bottom-style: solid;
  }

  .booking-details-left {
    border-right: none;
    border-bottom: 1px solid #e0e7e7;
    padding-bottom: 10px;
  }

  .booking-details-right {
    padding-top: 10px;
  }

  .side-by-side > * {
    width: 100% !important;
  }

  .collectedBox {
    margin-right: 0px;
  }

  .ant-steps {
    flex-direction: column;
  }
}

.booking-details-card-container {
  margin-top: 12px;
}
