.uploadButton {
  display: block;
  margin: auto;
  height: 100% !important;
  width: 100% !important;
  border: 0px dashed #d9d9d9 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  text-align: center !important;
  background: #fafafa !important;
}

.uploadContainer {
  display: block;
  width: 100% !important;
  height: 100% !important;
  border: 1px dashed #d9d9d9 !important;
  border-radius: 4px !important;
  text-align: center !important;
  background: #fafafa !important;
  padding: 40px;
}

.fileList {
  text-align: center;
}

.fileIcon {
  font-size: 56px;
  margin-bottom: 16px;
  color: #00b9c6 !important;
}

.delete {
  margin-left: 8px;
}
