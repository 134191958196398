.legendBoxRow {
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 25px;
  margin-bottom: 20px;
}

.legendBox {
  height: 18px;
  width: 18px;
  margin-right: 15px;
  border-radius: 3px;
}

.legendBox:hover {
  height: 23px;
  width: 23px;
  margin-right: 10px;
  margin-top: -3px;
}

.legendBox:last-of-type {
  margin-right: 0;
}

.legendBox:last-of-type:hover {
  margin-right: -5px;
}
