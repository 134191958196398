.float-right {
  display: flex;
  justify-content: flex-end;
}

.float-center {
  display: flex;
  justify-content: center;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom10 {
  margin-bottom: 10px;
}
.superbig-font {
  font-size: 20px !important;
  font-weight: 450 !important;
}
.verybig-font {
  font-size: 15px !important;
  font-weight: 450 !important;
}

.big-font {
  font-size: 13.5px !important;
  font-weight: 450 !important;
}

.medium-font {
  font-size: 12.5px !important;
}

.small-font {
  font-size: 11px !important;
}

.word-wrap {
  white-space: normal;
}

.grey {
  color: gray;
}

.gray {
  color: #bdbdbd;
}

.red {
  color: rgb(163, 0, 0);
}

.blue {
  color: #00b9c6;
}

.hr {
  margin: 10px 0;
}

.po-equals {
  border-top: 1.5px dotted #bdbdbd;
  border-bottom: 1.5px dotted #bdbdbd;
  padding: 3px 0;
  margin: 5px 0;
}

.calendarSummary {
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  background-color: #eeeeee;
  padding: 15px;
}

.bold {
  font-weight: bold;
}

.smallLabel {
  font-size: 1rem;
}

.messages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  font-weight: bold;
  color: #ea9302;
  text-align: center;
}

.protectionBadgeContainer {
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
  justify-content: flex-end;
}

.protectionBadgePos {
  text-align: center;
  align-self: flex-end;
  width: 100px;
  background-color: #d5ecdb;
  border-radius: 0px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 4px;
  color: #289d48;
}

.protectionBadgeNeg {
  text-align: center;
  align-self: flex-end;
  width: 120px;
  background-color: #ffc8c8;
  border-radius: 0px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 4px;
  color: #ff5630;
}

.protectButton {
  width: 100%;
}

hr {
  display: flex;
  margin: 15px 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 1px;
  color: #bdbdbd;
}

.protectedDetails {
  background-color: #d5ecdb;
  padding: 16px;
  font-size: 12px;
}

.unprotectedDetails {
  background-color: #ffc8c8;
  padding: 16px;
  font-size: 12px;
}

.showMoreLink {
  color: #00b9c6;
  font-size: 14px;
}

.showMoreLink:hover {
  cursor: pointer;
}

.protected-icon {
  font-size: 18px;
}

.hostProtectButtons {
  margin-top: 10px;
}

@media print {
  .calendarSummary {
    height: 100%;
    overflow-y: auto;
  }
}

.protected-text {
  word-break: break-all;
  text-align: right;
}

.protected-date {
  text-align: right;
}

.protected-information {
  font-size: 10px;
}
