.presettask-form-card {
  margin: 40px;
}
.presettask-form-wrapper {
  display: flex;
}
.presettask-form-list {
  padding: 0px 18px;
  display: block;
  width: 100%;
}
.presettask-form-list-half {
  padding: 0px 18px;
  display: block;
  width: 50%;
}
.presettask-form-list-quarter {
  padding: 0px 18px;
  display: block;
  width: 25%;
}
.presettask-table-wrapper {
  display: block;
  width: 100%;
}
.presettask-table-column {
  min-width: 6em;
}
.presettask-button-wrapper {
  display: block;
  width: 100%;
}

.presettask-info-wrapper {
  width: 100%;
  margin-bottom: 15px;
}
.save-and-delete-buttons {
  margin: 40px;
  margin-top: -20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.inputaddon .ant-input-group-addon:last-child {
  background-color: #00b9c6;
}
@media screen and (max-width: 650px) {
  .presettask-form-card {
    margin: 10px;
  }
  .presettask-form-list {
    display: block;
    width: 100%;
  }
  .save-and-delete-buttons {
    margin: 40px;
    margin-top: 0px;
  }
}
