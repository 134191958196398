.sp-list-card {
  margin: 30px;
  min-width: 800px;
}

.sp-controls {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sp-csv {
  float: right;
}
