.logoContainer {
  background: white;
  display: block;
  text-align: center;
  height: auto;
}

.logo {
  height: 90px;
}
